import React, { useState, ReactNode, MouseEvent, FocusEvent, useRef, useEffect } from 'react';
import { useUser } from '@/app/hooks/useUser'; // Assuming you have a useUser hook for user state
import BaseModal from '@/app/base-ui/BaseModalUI';
import { useRouter } from 'next/navigation';
import { paths } from '@/app/constants/paths';

interface PermissionWrapperProps {
    children: ReactNode;
}

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({ children }) => {
    const { auth } = useUser();
    const [modalOpen, setModalOpen] = useState(false);
    const blockActionsRef = useRef(false);
    const router = useRouter();
    const checkPermissions = (): boolean => {
        const hasPermission = auth?.isRegistered ?? false;
        if (!hasPermission) {
            setModalOpen(true);
            blockActionsRef.current = true;
        }
        return hasPermission;
    };

    const handleInteraction = (e: MouseEvent | FocusEvent) => {
        if (blockActionsRef.current) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (!checkPermissions()) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        blockActionsRef.current = true; // Block further actions
    };

    const handleAccept = () => {
        // Redirect to login
        router.replace(paths.auth.signIn);
    };

    useEffect(() => {
        if (!modalOpen) {
            blockActionsRef.current = false; // Reset blocking when modal is closed
        }
    }, [modalOpen]);

    return (
        <>
            <div
                onClick={handleInteraction}
                onMouseDown={handleInteraction}
                onFocus={handleInteraction}
                style={{ pointerEvents: 'auto' }}
            >
                {children}
            </div>
            {modalOpen && (
                <BaseModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    title="로그인이 필요한 기능입니다. "
                    description="로그인 페이지로 이동하시겠습니까?"
                    showCancel
                    showAccept
                    onCancel={handleCloseModal}
                    onAccept={handleAccept}
                    cancelText="취소"
                    acceptText="로그인"
                />
            )}
        </>
    );
};

export default PermissionWrapper;
