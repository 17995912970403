import React, { useState } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import { BannerNull } from '@/public/icon';
import Image from 'next/image';

interface PostCardProps {
    src: string;
    alt: string;
    height: string | number;
    borderRadius?: number;
}

const PostCard: React.FC<PostCardProps> = ({ src, alt, height, borderRadius = 4 }) => {
    const [imageError, setImageError] = useState(false);

    return imageError ? (
        <Box sx={{ height, display: 'flex', borderRadius: 4, background: '#F1F1F1', alignItems: 'center', justifyContent: 'center' }}>
            <BannerNull />
        </Box>
    ) : (
        <Image
            src={src}
            alt={alt}
            height={0}
            priority={true}
            width={0}
            sizes="(max-width: 375px) 100vw, (max-width: 768px) 50vw, 100vw"
            onError={() => setImageError(true)}
            style={{ height, width: '100%', borderRadius: borderRadius * 4, cursor: 'pointer', objectFit: 'cover' }}
        />
    );
};

export default PostCard;
