import { HEIGHT_FIXED_BANNER_TABLET, MAX_SCREEN_TABLET, MAX_SCREEN_WIDTH, MIN_SCREEN_TABLET } from '@/app/constants/layoutConfig';
import React from 'react';

interface ResponsiveImageProps {
    image: string;
    imageMobile?: string;
    alt?: string;
    link?: string;
    minHeight?: string;
    height?: string;
    isFixedHeight?: boolean;
    onError?: () => void;
    onLoad?: (e: any) => void;
    onClick?: () => void;
    style?: React.CSSProperties;
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
    image,
    imageMobile,
    alt = 'Banner Image',
    link = '',
    minHeight = '370px',
    onError,
    onLoad,
    onClick,
    style = {},
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        } else if (link) {
            window.location.href = link;
        }
    };

    const isNonMobile = typeof window !== 'undefined' && window.innerWidth > MIN_SCREEN_TABLET;

    return (
        <picture style={{ width: '100%', height: 'auto' }}>
            {imageMobile && (
                <source
                    media={`(max-width: ${MIN_SCREEN_TABLET}px)`}
                    srcSet={imageMobile}
                />
            )}
            <source
                media={`(min-width: ${MIN_SCREEN_TABLET}px)`}
                srcSet={image}
            />
            <img
                src={image} // Fallback image
                alt={alt}
                loading="lazy"
                style={{
                    minHeight: minHeight,
                    // height: isFixedHeight && isNonMobile ? height || `${HEIGHT_FIXED_BANNER_TABLET}px` : 'auto',
                    height: `${HEIGHT_FIXED_BANNER_TABLET}px`,
                    width: '100%',
                    borderRadius: '0px',
                    objectFit: 'cover',
                    display: 'block',
                    cursor: 'pointer',
                    ...style,
                }}
                onError={onError}
                onLoad={onLoad}
                onClick={onClick ? handleClick : () => null}
            />
        </picture>
    );
};

export default ResponsiveImage;
