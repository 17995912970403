import React, { useState } from 'react';
import { IconButton, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keyframes } from '@emotion/react';

const rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const loadingIconStyles: React.CSSProperties = {
    animation: `${rotating} 1.2s linear infinite`,
};

const RetryButton = ({ onClick }: { onClick: () => void }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    const handleClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
            onClick?.();
        }, 2000);
    };

    return (
        <IconButton
            sx={{
                padding: '8px',
                border: '0',
                borderRadius: '4px',
                width: '40px',
                height: '40px',
                margin: '0 auto',
                display: 'block',
                background: 'none',
                cursor: loading ? 'wait' : 'pointer',
                '&:hover svg, &:active svg': {
                    background: 'none',
                    transform: 'rotate(180deg)',
                    transition: 'transform 0.5s',
                },
            }}
            onClick={handleClick}
            disabled={loading}
        >
            <RefreshIcon sx={loading ? { ...loadingIconStyles, fill: theme.palette.primary.main } : { fill: theme.palette.grayscale.gray06 }} />
        </IconButton>
    );
};

export default RetryButton;
