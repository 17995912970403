'use client';
import Layout from '@/app/layout/MainLayout';
import Banners from '@/app/components/Home/Banners';
import NavBar from '@/app/components/MUI/NavBar';
import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { PointDot } from '@/public/icon';
import Image from 'next/image';
import moment from 'moment';
import { clearUserCookies, getAccessToken, setCookieUser, setExpiredToken } from '@/app/helpers/cookiesMethod';
import { useRouter } from 'next/navigation';
import { useUser } from '@/app/hooks/useUser';
import { usePoints } from '@/app/contexts/points-context';
import utilsService from '@/app/helpers/utilsService';
import NotificationManager from '@/app/lib/firebase/action';
import { NotificationService } from '@/app/service/notifications';

export default function HomePage() {
    const router = useRouter();
    const { points } = usePoints();
    const { user } = useUser();
    const [point, setPoint] = useState<any>(points);
    useEffect(() => {
        setPoint(points);
    }, [points]);
    const { updateDataFromCookiesForProvider, auth } = useUser();
    useEffect(() => {
        const handleMessage = async (event: any) => {
            const data = JSON.parse(JSON.stringify(event.detail));
            const isAllowNotifications = data.IS_ALLOW_NOTIFICATIONS_WEBVIEW;
            const fcmToken = data.FCM_TOKEN;
            const platform = data.PLATFORM;
            const getIsAllowNotifications = localStorage.getItem('isAllowNotifications');
            //the first time load app --> receive status from native app
            const expiredToken = data.expired_at;
            auth?.updateRegistrationStatus(data?.is_registered ?? false);
            setExpiredToken(expiredToken);
            if (data.is_registered) {
                const userObject = {
                    id: data?.user?.id,
                    nickname: data?.user?.nickname || '',
                    email: data?.user?.email || undefined,
                    contacts: data?.user?.contacts ?? [],
                    isStaff: data?.user?.is_staff ?? null,
                    isInfluencer: data?.user?.is_influencer ?? null,
                    isExpert: data?.user?.is_expert ?? null,
                    avatarUrl: data?.user?.avatar ?? null,
                    isGoogleLoggedIn: data?.user?.is_google_logged_in ?? false,
                    isAppleLoggedIn: data?.user?.is_apple_logged_in ?? false,
                    phoneNumber: data?.user?.phone_number ?? null,
                    updatedAt: data?.user?.updated_at ? moment(data?.user.updated_at) : moment(),
                    createdAt: data?.user?.created_at ? moment(data?.user.created_at) : moment(),
                };
                const tokenCrash = getAccessToken();
                if (tokenCrash) await clearUserCookies();
                await setCookieUser({ user: userObject, accessToken: data?.access_token, refreshToken: '', fireBaseToken: data?.access_token });
                await updateDataFromCookiesForProvider?.();
                router.refresh();
                window?.ReactNativeWebView?.postMessage('REFRESHED PAGE BUT WILL NOT UNMOUNT');
                localStorage.setItem('isAllowNotifications', String(isAllowNotifications ?? false));
                localStorage.setItem('fcmToken', String(fcmToken));
                await updateFCM(platform, fcmToken);
                if (isAllowNotifications && !getIsAllowNotifications) {
                    alert(`Successfully enable => then ${isAllowNotifications && !getIsAllowNotifications}`);
                    window?.ReactNativeWebView?.postMessage('IS TURN ON ONLY ONE TIME ENABLE BUTTON SYSTEM');
                    await NotificationService.enableNotifications({ notificationType: 'is_system', isEnable: 1 });
                    await NotificationService.enableNotifications({ notificationType: 'is_event', isEnable: 1 });
                    //if true && isAllowNotifications is not existing already => turn on
                    // setReceiver(true);
                    // const notificationManager = NotificationManager.getInstance();
                    // await notificationManager.turnOnSystemNotifications();
                    // await notificationManager.turnOnEventNotifications();
                }
                if (!isAllowNotifications && !getIsAllowNotifications) {
                    await NotificationService.enableNotifications({ notificationType: 'is_system', isEnable: 0 });
                    await NotificationService.enableNotifications({ notificationType: 'is_event', isEnable: 0 });
                }
            }
        };
        if (window?.ReactNativeWebView) {
            window?.ReactNativeWebView?.postMessage('listenerReady');
            window.addEventListener('joysamData', handleMessage);
        }
        return () => {
            window.removeEventListener('joysamData', handleMessage);
        };
    }, []);

    const updateFCM = async (platform: string, fcm: string) => {
        try {
            if (!fcm) return;
            await NotificationService.updateNotifications({ platform, fcm });
        } catch (error) {}
    };

    return (
        <Layout
            sx={{ boxShadow: '0px 0px 120px 0px #00000014' }}
            navBar={
                <NavBar
                    title={<TitleComponent />}
                    rightContent={user?.id ? <RightContent point={point as any} /> : null}
                />
            }
            content={
                <>
                    {' '}
                    <Banners />{' '}
                </>
            }
        />
    );
}

const RightContent = ({ point }: { point: number }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '6px',
            }}
        >
            <PointDot
                width={16}
                height={16}
            />

            <Typography
                variant="h2_14Bold"
                color={'grayscale.gray07'}
                textAlign={'center'}
            >
                {utilsService.formatNumber(point) ?? 0}
            </Typography>
        </Box>
    );
};

const TitleComponent = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '20px',
                borderRadius: 8,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Image
                src="/images/logo.svg"
                alt="Logo"
                width={43}
                height={20}
                priority
            />
        </Box>
    );
};
