import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import Masonry from 'react-masonry-css';
const gutterSpace = '20px';
const columnClassName = 'my-masonry-grid_column_skeleton';
const masonryStyles: any = {
    ml: `-${gutterSpace}`,
    display: 'flex',
    flexDirection: 'row',
    rowGap: '30px',
};

const selector = `& .${columnClassName}`;

masonryStyles[selector] = {
    pl: gutterSpace,
    backgroundClip: 'padding-box',
};

const breakpointColumnsObj = {
    350: 1,
    468: 1,
    751: 2,
};

const MasonrySkeleton = ({amount = 8}: {amount? : number}) => {
    const skeletonItems = Array.from({ length: amount }); // 6 skeleton items

    return (
        <Box
            component={Masonry}
            columnClassName={columnClassName}
            breakpointCols={breakpointColumnsObj}
            sx={{
                '& .my-masonry-grid_column > div': {
                    marginBottom: '30px',
                    '&:last-of-type': {
                        marginBottom: 0,
                    },
                },

                ...masonryStyles,
            }}
            className=""
        >
            {skeletonItems.map((_, index) => (
                <Box
                    key={index}
                    sx={{ borderRadius: 2, overflow: 'hidden', marginBottom: 2}}
                >
                    <Skeleton
                        variant="rectangular"
                        height={210}
                        width={'100%'}
                        animation="wave"
                    />

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ padding: 2, pl: 0 }}
                    >
                        <Skeleton
                            variant="circular"
                            width={40}
                            height={40}
                            animation="wave"
                        />
                        <Stack spacing={1}>
                            <Skeleton
                                variant="text"
                                width={120}
                                height={20}
                                animation="wave"
                            />
                            <Skeleton
                                variant="text"
                                width={80}
                                height={15}
                                animation="wave"
                            />
                        </Stack>
                    </Stack>
                </Box>
            ))}
        </Box>
    );
};

export default MasonrySkeleton;
