export interface BannerImageProps {
    original: string;
    large: string;
    small: string;
    medium: string
}

interface IBanner {
    id: number;
    page: string;
    position: string;
    title: string;
    isActive: boolean;
    isLimited: number;
    link: string | null;
    startTime: string | null;
    endTime: string | null;
    image: BannerImageProps;
    sortOrder: number;
    viewCount: number;
    createdAt: string;
}

export class Banner implements IBanner {
    public id: number;
    public page: string;
    public position: string;
    public title: string;
    public isActive: boolean;
    public isLimited: number;
    public link: string | null;
    public startTime: string | null;
    public endTime: string | null;
    public image: BannerImageProps;
    public imageMobile: BannerImageProps;
    public sortOrder: number;
    public viewCount: number;
    public createdAt: string;

    constructor(data: any) {
        this.id = data.id;
        this.page = data.page;
        this.position = data.position;
        this.title = data.title;
        this.isActive = data.is_active;
        this.isLimited = data.is_limited;
        this.link = data.link;
        this.startTime = data.start_time;
        this.endTime = data.end_time;
        this.image = data.image;
        this.imageMobile = data.image_mobile
        this.sortOrder = data.sort_order;
        this.viewCount = data.view_count;
        this.createdAt = data.created_at;
    }
}
