'use client';
import * as React from 'react';
import Box from '@mui/material/Box';
import Navigation from '@/app/components/MUI/BottomNavigation';
import { SxProps } from '@mui/material';
import { MAX_SCREEN_WIDTH, MIN_WIDTH } from '@/app/constants/layoutConfig';
import MUIBoxScrollBehavior from '@/app/components/MUI/BoxScrollBehavior';

interface MainLayoutProps {
    content: React.ReactNode;
    navBar: React.ReactNode;
    sx?: SxProps;
    hideNavigation?: boolean;
    overrideNavigation?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ content, navBar, sx = {}, hideNavigation = false, overrideNavigation }) => {
    return (
        <Box
            id="main-layout-joy"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                minWidth: MIN_WIDTH,
                maxWidth: MAX_SCREEN_WIDTH,
                ...sx,
            }}
        >
            {navBar}
            <Box sx={{ pt: '48px', paddingBottom: hideNavigation && !overrideNavigation ? 0 : '60px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>{content}</Box>
            {(!hideNavigation || overrideNavigation) && (overrideNavigation || <Navigation />)}
        </Box>
    );
};

export default MainLayout;
