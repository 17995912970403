import React, { useState } from 'react';
import { AttendanceCheck, FreeTrial, MissionParticipation, QualificationTest, RouletteIcon, SubmitYourWork, WayToCome } from '@/public/icon';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';
import BaseModal from '@/app/base-ui/BaseModalUI';
import PermissionWrapper from '@/app/components/Auth/PermissionWrapper';
import { paths } from '@/app/constants/paths';
import { useGlobalDialog } from '@/app/contexts/global-dialog';
import { useUser } from '@/app/hooks/useUser';

const ListActions = () => {
    const router = useRouter();
    const [openModal, setOpenModal] = useState(false);
    const { checkPermissions } = useUser();
    const [modalContent, setModalContent] = useState({ title: '', description: '' });
    const { loadingDialog } = useGlobalDialog();
    const isDevelopment = process.env.APP_ENV === 'development' || process.env.APP_ENV === 'local';

    const items = [
        { type: 'attendance', icon: <AttendanceCheck />, label: '출석체크' },
        { type: 'roulette', icon: <RouletteIcon />, label: '룰렛' },
        { type: 'mission_participation', icon: <MissionParticipation />, label: '미션참여' },
        { type: 'submit-work', icon: <SubmitYourWork />, label: '작품투고' },
        { type: 'contact', icon: <WayToCome />, label: '오시는길' },
        // { icon: <QualificationTest />, label: '자격시험' },
        // { icon: <WayToCome />, label: '오시는길' },
    ];

    const redirectTo = async (type: string) => {
        loadingDialog.open();
        if (['roulette'].includes(type)) {
            loadingDialog.close();
            return router.push(`/events/${type}/latest`, { scroll: false });
        }
        if (!['submit-work', 'contact', 'mission_participation', 'roulette'].includes(type)) {
            const checkPermission = await checkPermissions();
            if (checkPermission) {
                loadingDialog.close();
                return router.push(`/events/${type}/latest`, { scroll: false });
            } else {
                return router.push('/login');
            }
        }
        if (['mission_participation'].includes(type)) {
            loadingDialog.close();

            return router.push(`/community/challenges/?filtered=participable`);
        }
        if (['contact'].includes(type)) {
            loadingDialog.close();

            return router.push(`/directions`, { scroll: false });
        }
        setModalContent({
            title: '이 기능은 열심히 개발중입니다.<br />멋진 업데이트로 찾아뵙겠습니다!',
            description: '',
        });
        setOpenModal(true);
        loadingDialog.close();
        return;
    };

    return (
        <>
            <Box sx={{ paddingTop: '18px', paddingBottom: '18px', paddingRight: '20px', paddingLeft: '20px', gap: 15, display: { tablet: 'flex', laptop: 'flex', mobile: 'none' } }}>
                {items.map((item, index) => {
                    if (['attendance', 'roulette', 'submit-work'].includes(item.type)) {
                        return (
                            <PermissionWrapper key={index}>
                                <Box
                                    key={index}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2.5, cursor: 'pointer' }}
                                    component="div"
                                    onClick={() => redirectTo(item.type)}
                                >
                                    <ButtonBase
                                        onClick={() => redirectTo(item.type)}
                                        component="div"
                                        sx={{ borderRadius: 1, padding: 2, margin: -2 }}
                                    >
                                        {item.icon}
                                    </ButtonBase>

                                    <Typography variant="h2_10Medium" lineHeight="100%">{item.label}</Typography>
                                </Box>
                            </PermissionWrapper>
                        );
                    }
                    return (
                        <Box
                            key={index}
                            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2.5, cursor: 'pointer' }}
                            component="div"
                            onClick={() => redirectTo(item.type)}
                        >
                            <ButtonBase
                                onClick={() => redirectTo(item.type)}
                                component="div"
                                sx={{ borderRadius: 1, padding: 2, margin: -2 }}
                            >
                                {item.icon}
                            </ButtonBase>

                            <Typography variant="h2_10Medium" lineHeight="100%">{item.label}</Typography>
                            </Box>
                    );
                })}
            </Box>
            <Box
                sx={{
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    gap: 5,
                    display: { tablet: 'none', laptop: 'none', mobile: 'flex' },
                    justifyContent: 'center',
                }}
            >
                {items.map((item, index) => (
                    <Box
                        key={index}
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2.5, }}
                    >
                        <ButtonBase
                            onClick={() => redirectTo(item.type)}
                            component="div"
                            sx={{borderRadius: 1, padding: 2, margin: -2}}
                        >
                            
                        {item.icon}
                        </ButtonBase>
                        <Typography
                            variant="h2_10Medium"
                            lineHeight="100%"
                        >
                            {item.label}
                        </Typography>
                    </Box>
                ))}
            </Box>
            {openModal && (
                <BaseModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title={modalContent.title}
                    description={modalContent.description}
                    showAccept={true}
                    showCancel={false}
                    onCancel={() => setOpenModal(false)}
                    onAccept={() => setOpenModal(false)}
                    acceptText="확인"
                />
            )}
        </>
    );
};

export default ListActions;
