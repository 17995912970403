import 'swiper/css';
import './swiper.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import { Box, Grid } from '@mui/material';
import { MAX_SCREEN_WIDTH } from '@/app/constants/layoutConfig';
import { SwiperOptions } from 'swiper/types';

// Register Swiper modules
SwiperCore.use([Navigation]);

interface GenericSliderProps extends SwiperOptions {
    data: any[];
    bulletType?: 'default' | 'white';
    showButtonNextPrev?: boolean;
    clickable?: boolean;
    style?: CSSProperties;
    slidesPerView?: number | 'auto';
    onSlideChange?: (index: number) => void;
    onTouchMoveStart?: (value: boolean) => void;
    children: (item: any, index: number) => React.ReactNode;
    marginRightItem?: string | number;
}

const GenericSlider: React.FC<GenericSliderProps> = ({
    bulletType = 'default',
    slidesPerView,
    style = {},
    data = [],
    marginRightItem,
    showButtonNextPrev = false,
    clickable = false,
    onSlideChange,
    onTouchMoveStart,
    children,
    ...other
}) => {
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const timeout = useRef<NodeJS.Timeout | null>(null);
    const navigationPrevRef = useRef<HTMLDivElement>(null);
    const navigationNextRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (swiper) {
            const handleSlideChange = () => {
                const currentIndex = swiper.activeIndex;
                setActiveIndex(currentIndex);
                if (onSlideChange) {
                    onSlideChange(currentIndex);
                }
            };
            swiper.on('slideChange', handleSlideChange);
            return () => {
                swiper.off('slideChange', handleSlideChange);
            };
        }
    }, [swiper, onSlideChange]);

    const swipeRight = () => {
        if (swiper) {
            swiper.slideNext(500, true);
        }
    };

    const swipeLeft = () => {
        if (swiper) {
            swiper.slidePrev(500, true);
        }
    };

    const onTouchMove = (e: SwiperCore) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        onTouchMoveStart?.(true);
    };

    const onTouchEnd = (e: SwiperCore) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        timeout.current = setTimeout(() => {
            onTouchMoveStart?.(false);
        }, 400);
    };

    const items = data.map((item, index) => (
        <SwiperSlide
            key={index}
            style={{ width: 'auto', display: 'inline-flex' }}
        >
            {children(item, index)}
        </SwiperSlide>
    ));

    return (
        <Grid item>
            <Grid
                item
                sx={{ maxWidth: MAX_SCREEN_WIDTH }}
                style={{ backgroundColor: 'grayscale.gray02' }}
            >
                <Swiper
                    pagination={{ clickable: clickable }}
                    modules={clickable ? [Pagination, Navigation] : [Navigation]}
                    className={`swiper-container ${bulletType}`}
                    style={{ position: 'relative', ...style }}
                    initialSlide={0}
                    spaceBetween={marginRightItem ?? 22}
                    onSwiper={setSwiper}
                    onTouchMove={(swiper) => onTouchMove(swiper)}
                    onTouchEnd={onTouchEnd}
                    ///
                    onTouchMoveOpposite={(swiper) => onTouchMove(swiper)}
                    // simulateTouch={false}
                    // centeredSlides={false}
                    slidesPerView={slidesPerView ?? 'auto'}
                    watchSlidesProgress={true}
                    allowTouchMove={true}
                    {...other}
                    // onReachEnd={fetchMoreData}
                >
                    {items}
                    {showButtonNextPrev && (
                        <>
                            <Box
                                ref={navigationPrevRef}
                                className="swiper-button-prev"
                                onClick={swipeLeft}
                                style={{ display: activeIndex > 0 ? '' : 'none' }}
                            />
                            <Box
                                ref={navigationNextRef}
                                className="swiper-button-next"
                                onClick={swipeRight}
                            />
                        </>
                    )}
                </Swiper>
            </Grid>
        </Grid>
    );
};

export default GenericSlider;
