import CenteredBox from '@/app/base-ui/CenterBox';
import { Typography, useTheme } from '@mui/material';

const EmptyMessageHome = ({message}: {message: string}) => {
    const theme = useTheme();
    return (
        <CenteredBox
            sx={{
                padding: 5,
                flexDirection: 'column',
                borderRadius: '16px',
                alignItems: 'start',
                background: theme.palette.background.paper,
            }}
        >
            {' '}
            <Typography
                variant="h2_12Regular"
                textAlign="start"
                color="grayscale.gray05"
                dangerouslySetInnerHTML={{ __html: message }}
            />
        </CenteredBox>
    );
};

export default EmptyMessageHome;
