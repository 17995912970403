import SliderMedia from '@/app/base-ui/SwiperSliderMedia';
import BaseCardMedia from '@/app/base-ui/BaseCardMedia';
import FallbackUI from '@/app/components/MUI/FallBackImageUI';
import { Banner } from '@/app/models/banner/BannerModel';
import { useEffect, useRef, useState } from 'react';
import { homeService } from '@/app/service/homeService';
import { Box, Skeleton } from '@mui/material';
import CenteredBox from '@/app/base-ui/CenterBox';
import { Autoplay } from 'swiper/modules';
import { BannerNull } from '@/public/icon';
import utilsService from '@/app/helpers/utilsService';
import { logger } from '@/app/lib/logger';
import { useRouter } from 'next/navigation';
import ResponsiveImage from '@/app/components/MUI/ResponsiveImage';
import Image from 'next/image';

const env = process.env.APP_URL;
const MainBanner = () => {
    const [bannerData, setBannerData] = useState<Banner[] | []>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchControllerBanner = useRef<AbortController | null>(null);
    const router = useRouter();
    useEffect(() => {
        getBanner();
    }, []);

    const handleLoaded = (e: any) => {
        // setIsLoading(false);
    };

    async function getBanner(): Promise<void> {
        try {
            if (fetchControllerBanner.current) {
                fetchControllerBanner.current.abort();
            }
            fetchControllerBanner.current = new AbortController();
            const signal = fetchControllerBanner.current.signal;
            const { data }: { data: Banner[] } = await homeService.getBanners({ page: 'home', position: 'top', signal });
            const ParseBanner = data.map((banner: Banner) => new Banner(banner));
            setBannerData(ParseBanner);
            setIsLoading(false);
        } catch (error) {
            const errorMessage = utilsService.getErrorMessage(error);
            if (errorMessage !== 'canceled') {
                setIsLoading(false);
                return logger.error(errorMessage);
            }
        }
    }

    function gotoLink(url: string) {
        if (url && utilsService.isUrl(url)) {
            const parsedUrl = new URL(url);
            const domain = utilsService.normalizeDomain(parsedUrl.hostname);
            const currentDomain = utilsService.getAppDomain();
            // console.log('domain', domain, 'currentDomain', currentDomain);
            if (domain === currentDomain) {
                router.push(parsedUrl.pathname);
            } else {
                window.open(url, '_blank');
            }
        }
    }

    if (isLoading) {
        return (
            <Box sx={{ position: 'relative' }}>
                <Skeleton
                    sx={{ height: 370 }}
                    animation="wave"
                    variant="rectangular"
                />
                <CenteredBox sx={{ flexDirection: 'row', gap: 2, position: 'absolute', bottom: 20 }}>
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={12}
                        height={12}
                        // sx={{ backgroundColor: 'grayscale.gray07' }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={12}
                        height={12}
                        // sx={{ backgroundColor: 'grayscale.gray07' }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={12}
                        height={12}
                        // sx={{ backgroundColor: 'grayscale.gray07' }}
                    />
                </CenteredBox>
            </Box>
        );
    }

    if (bannerData.length < 1 && !isLoading) {
        return (
            <CenteredBox sx={{ minHeight: '370px', width: '100%', borderRadius: '0px', background: '#F1F1F1' }}>
                <BannerNull />
            </CenteredBox>
        );
    }
    const aspectRatio = 375 / 370;
    const paddingTop = `${(aspectRatio * 100).toFixed(2)}%`;
    return (
        <SliderMedia
            bulletType="white"
            data={bannerData}
            showNumbersImg={bannerData.length > 1}
            slidesPerView={1}
            autoplay={{
                delay: 3000,
            }}
            loop={bannerData.length > 1}
            module={bannerData.length > 1 ? [Autoplay] : []}
            showButtonNextPrev={false}
            onSlideChange={(index) => {
                // Handle slide change if needed
            }}
        >
            {(data: Banner, index, hasError, handleError) =>
                hasError ? (
                    <FallbackUI
                        width={'100%'}
                        maxWidth={'100%'}
                        height={'370px'}
                        maxHeight={'370px'}
                        marginRight="0"
                        description="The url or image is invalid"
                    />
                ) : (
                    <ResponsiveImage
                        image={data?.image?.original}
                        imageMobile={data?.imageMobile?.original}
                        alt="Home Banner"
                        link={data.link ?? ''}
                        // height="370px"
                        style={{maxHeight: 370}}
                        isFixedHeight={true}
                        onError={handleError}
                        onLoad={handleLoaded}
                        onClick={() => gotoLink(data.link ?? '')}
                    />
                )
            }
        </SliderMedia>
    );
};

export default MainBanner;
