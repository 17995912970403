'use client';
import * as React from 'react';
import { Avatar, Box, Button, Typography, useTheme } from '@mui/material';

import { motion } from 'framer-motion';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { CuriousCancellation, CuriousChallenges, CuriousCommon, CuriousExchange, CuriousLogin } from '@/public/icon';
import handleLogicText from '@/app/helpers/handleLogicContentPost';
import CenteredBox from '@/app/base-ui/CenterBox';
import GenericSlider from '@/app/base-ui/GenericSlider';
import { homeService } from '@/app/service/homeService';
import { logger } from '@/app/lib/logger';
import { useRouter } from 'next/navigation';
import EmptyMessageHome from '@/app/components/MUI/EmptyMessageHome';
import { ImageObjectTypes } from '@/app/models/avatar/avatar';

interface Type {
    id: number;
    slug: string;
    name_ko: string;
    name_en: string;
    name_vn: string | null;
}

interface Language {
    id: number;
    language_code: string;
    question: string;
    answer: string;
}

interface FAQ_Object {
    type: Type;
    languages: Language[];
}

interface FAQ {
    slug: string;
    name_ko: string;
    name_en: string;
    faq: FAQ_Object;
    name_vn: string;
    id: number;
    thumbnail: { large: string; original: string; medium: string; small: string };
}

const iconObject = { jeonce: <CuriousCommon />, ilban: <CuriousLogin />, rogeuin: <CuriousChallenges />, caelrinji: <CuriousCancellation />, cwisobanpum: <CuriousExchange /> } as any;

const Curious: React.FC = ({}) => {
    const theme = useTheme();
    const [listFAQ, setListFAQ] = React.useState<FAQ[]>([]);
    const router = useRouter();
    const fetchControllerBanner = React.useRef<AbortController | null>(null);

    const fetchListFaqHome = async () => {
        try {
            if (fetchControllerBanner.current) {
                fetchControllerBanner.current.abort();
            }
            fetchControllerBanner.current = new AbortController();
            const signal = fetchControllerBanner.current.signal;
            const { data } = await homeService.getFaqHome(signal);
            setListFAQ(
                data
                    .filter((item: FAQ) => item.faq !== null)
                    .map((item: FAQ) => ({
                        title: item.faq?.languages?.[0]?.question ?? '아직 관련 질문이 없습니다.',
                        type: item.slug,
                        name: item.name_ko,
                        thumbnail: item.thumbnail,
                        hastag: `#${item.name_ko}`,
                        icon: iconObject[item.slug] as any,
                        color: 'rgba(126, 44, 245, 0.10)',
                    }))
            );
        } catch (error) {
            // logger.error(error);
        }
    };

    React.useEffect(() => {
        fetchListFaqHome();
    }, []);

    return (
        <Box sx={{ display: 'flex', gap: 6, flexDirection: 'column' }}>
            <CenteredBox
                sx={{ justifyContent: 'space-between', lineHeight: '100%', maxHeight: 18, height: '100%' }}
                onClick={() => null} //todo-something
            >
                <Box sx={{lineHeight: '100%'}}>
                    {' '}
                    <Typography variant="h2_18Bold" lineHeight="100%">이런게 궁금해요</Typography>
                </Box>
                <Button
                    endIcon={<ArrowRightIcon sx={{ width: '15px', height: '15px', color: 'gray', margin: '-6px -8px' }} />}
                    onClick={() => router.push(`/support/faq`)}
                >
                    <Typography
                        variant="h2_12Regular"
                        color="grayscale.gray06"
                    >
                        더보기
                    </Typography>
                </Button>
            </CenteredBox>
            <Box>
                {listFAQ.length < 1 ? (
                    <EmptyMessageHome message="등록된 FAQ가 없습니다." />
                ) : (
                    <GenericSlider
                        slidesPerView={'auto'}
                        data={listFAQ}
                        bulletType="default"
                        showButtonNextPrev={false}
                        style={{ paddingBottom: '20px' }}
                        clickable={false}
                        marginRightItem={14}
                    >
                        {(item, index) => (
                            <motion.div
                                key={index}
                                initial={{ x: -90, opacity: 0.1 * index }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{
                                    duration: 0.5,
                                    delay: index * 0.1,
                                }}
                            >
                                <Box
                                    sx={{
                                        borderRadius: '16px',
                                        width: '144px',
                                        height: '204px',
                                        position: 'relative',
                                        border: `1px solid ${theme.palette.grayscale.gray03}`,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box sx={{ p: 3.75, display: 'flex', flexDirection: 'column' }}>
                                        <CenteredBox sx={{ flexDirection: 'column', gap: 3, mb: 3.5, cursor: 'pointer' }}>
                                            {/* {item.icon} */}
                                            <Avatar
                                                src={item?.thumbnail?.original}
                                                sx={{ width: '36px', height: '36px' }}
                                            />
                                            <Typography
                                                variant="h2_12Medium"
                                                color={'primary.main'}
                                                sx={{ cursor: 'pointer' }}
                                                dangerouslySetInnerHTML={{ __html: handleLogicText.formatContentPost(item.hastag, true, `/support/faq?type=${item.type}`) }}
                                            ></Typography>
                                        </CenteredBox>
                                        <Typography
                                            variant="h2_12Medium"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 4,
                                                WebkitBoxOrient: 'vertical',
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </Box>
                                </Box>
                            </motion.div>
                        )}
                    </GenericSlider>
                )}
            </Box>
        </Box>
    );
};

export default Curious;
