import React, { forwardRef, useRef, useEffect, useState, ReactElement } from 'react';
import { Box, Theme, useTheme } from '@mui/material';
import RetryButton from './components/RetryButton';

interface InfiniteScrollProps extends React.HTMLAttributes<HTMLDivElement> {
    fetchNextPage: () => Promise<void>;
    hasNextPage: boolean;
    retry?: () => void;
    isLoading: boolean;
    loadingMessage: React.ReactNode | ReactElement;
    endingMessage?: React.ReactNode;
    errorMessage?: React.ReactNode;
    hasErrors?: boolean;
    showLoading?: boolean;
}

const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(
    ({ fetchNextPage, hasNextPage, retry, isLoading, endingMessage, loadingMessage, hasErrors, errorMessage, children, showLoading = true, ...props }, ref) => {
        const observerTarget = useRef<HTMLDivElement>(null);
        const [shouldFetch, setShouldFetch] = useState<boolean>(true);
        const [hasError, setHasError] = useState<boolean>(false);
        const [hasErrorOutSide, setHasErrorOutSide] = useState<boolean>(hasErrors ?? false);
        const theme = useTheme();

        useEffect(() => {
            setHasErrorOutSide(hasErrors ?? false);
        }, [hasErrors]);

        useEffect(() => {
            const observer = new IntersectionObserver(
                (entries) => {
                    if (entries[0]?.isIntersecting && !isLoading && shouldFetch && !hasError) {
                        handleFetchNextPage();
                    }
                },
                { threshold: 1 }
            );

            if (observerTarget.current) {
                observer.observe(observerTarget.current);
            }

            return () => observer.disconnect();
        }, [isLoading, shouldFetch, hasError]);

        useEffect(() => {
            if (!hasNextPage) {
                setShouldFetch(false);
            } else {
                setShouldFetch(true);
            }
        }, [hasNextPage]);

        const handleFetchNextPage = async () => {
            try {
                await fetchNextPage();
                setHasError(false);
            } catch (error) {
                console.error('Error fetching next page:', error);
                setHasError(true);
                setShouldFetch(false);
            }
        };

        const handleRetry = () => {
            retry?.();
            setHasError(false);
            setShouldFetch(true);
        };

        return (
            <div
                ref={ref}
                {...props}
            >
                {children}
                {showLoading && (
                    <Loader
                        theme={theme}
                        loadingMessage={loadingMessage}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        hasNextPage={hasNextPage}
                        hasError={hasError || hasErrorOutSide}
                        onRetry={handleRetry}
                    />
                )}
                <div ref={observerTarget} />
            </div>
        );
    }
);

interface LoaderProps {
    isLoading: boolean;
    hasNextPage: boolean;
    hasError: boolean;
    loadingMessage: React.ReactNode;
    errorMessage?: React.ReactNode;
    theme: Theme;
    onRetry: () => void;
}

export const Loader: React.FC<LoaderProps> = ({ isLoading, hasNextPage, hasError, loadingMessage, errorMessage, theme, onRetry }) => {
    if (isLoading && hasNextPage) {
        return <Box sx={{ background: theme.palette.background.default }}>{loadingMessage}</Box>;
    } else if (hasError) {
        return (
            <Box sx={{ paddingX: 4, mb: 4 }}>
                {errorMessage}
                <RetryButton onClick={onRetry} />
            </Box>
        );
    } else if (!isLoading && !hasNextPage) {
        return null;
    } else {
        return null;
    }
};

export default InfiniteScroll;
