import { Typography } from '@mui/material';
import parse from 'html-react-parser';
import TruncateMarkup from 'react-truncate-markup';

const TruncatedMarkupReact = ({ text, lineNumber, ellipsis }: { text: any; lineNumber: any; ellipsis?: React.ReactNode }) => {
    return (
        <TruncateMarkup
            lines={lineNumber ?? 1}
            ellipsis={ellipsis ?? '...'}
        >
            <Typography
                // dangerouslySetInnerHTML={{ __html:  }}
                variant="h2_12Regular"
                sx={{
                    '& p': {
                        margin: 0,
                        lineHeight: 'normal',
                        // display: 'flex',
                        // alignItems: 'center',
                    },
                    '& ul': {
                        paddingInlineStart: '25px',
                    },
                    '& a': { color: 'primary.main' }, // handle case a is link nested link
                }}
            >
                {parse(text)}
            </Typography>
        </TruncateMarkup>
    );
};

export default TruncatedMarkupReact;
