'use client';

import moment, { Moment } from 'moment';
import { DateType } from '../models/date';

export const formatTime = (createdAt: DateType): string => {
    const currentTime = moment();
    let createAtTime: Moment;

    if (typeof createdAt === 'string') {
        createAtTime = moment(createdAt);
    } else if (createdAt instanceof Date) {
        createAtTime = moment(createdAt);
    } else {
        return '';
    }

    const timeDiff = currentTime.diff(createAtTime);
    const seconds = moment.duration(timeDiff).asSeconds();
    const minutes = moment.duration(timeDiff).asMinutes();
    const hours = moment.duration(timeDiff).asHours();
    const days = moment.duration(timeDiff).asDays();
    const weeks = moment.duration(timeDiff).asWeeks();
    const years = moment.duration(timeDiff).asYears();

    if (seconds < 60) {
        return '방금 전';
    } else if (minutes < 60) {
        return `${Math.floor(minutes)}분 전`;
    } else if (hours < 24) {
        return `${Math.floor(hours)}시간 전`;
    } else if (days < 7) {
        return `${Math.floor(days)}일 전`;
    } else if (weeks < 52) {
        return `${Math.floor(weeks)}주 전`;
    } else {
        return `${Math.floor(years)}년 전`;
    }
};
