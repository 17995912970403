import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { decrypt, encrypt } from './cryptoMethods';
import moment from 'moment';

interface UserData {
    user?: any;
    accessToken?: string;
    refreshToken?: string;
    fireBaseToken?: string;
}

const setOnlyUser = ({ user}: UserData): void => {
    setCookie('user', encrypt(JSON.stringify(user), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
};


const setCookieUser = ({ user, accessToken, refreshToken, fireBaseToken }: UserData): void => {
    accessToken && setCookie('_access', encrypt(JSON.stringify(accessToken), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
    setCookie('_refresh', encrypt(JSON.stringify(refreshToken), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
    setCookie('_fb_token', encrypt(JSON.stringify(fireBaseToken), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
    setCookie('user', encrypt(JSON.stringify(user), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
};

const setAuthIgnoreCookie = (COOKIE_NAME: string, hours: number) => {
    const now = new Date();
    const expiry = new Date(now.getTime() + hours * 60 * 60 * 1000);
    const encryptedExpiry = encrypt(JSON.stringify(expiry), process.env.SECRET_KEY);
    setCookie(COOKIE_NAME, encryptedExpiry, { expires: expiry });
};

const clearUserCookies = (): void => {
    deleteCookie('_access', { httpOnly: false, secure: true });
    deleteCookie('_refresh', { httpOnly: false, secure: true });
    deleteCookie('_fb_token', { httpOnly: false, secure: true });
    deleteCookie('user', { httpOnly: false, secure: true });
};

const clearAuthIgnoreCookie = (COOKIE_NAME: string) => {
    deleteCookie(COOKIE_NAME);
};

const deleteCookieAlert = () => {
    const today = moment();
    const expires = moment().add(1, 'day').startOf('day');
    setCookie('badgeChallengeAlertClosed', today.format('YYYY-MM-DD'), { expires: expires.toDate() });
};

const updateCookie = ({ accessToken, user }: UserData): void => {
    setCookie('_access', encrypt(JSON.stringify(accessToken), process.env.SECRET_KEY), {  maxAge: 31536000000, httpOnly: false, secure: true });
    setCookie('user', encrypt(JSON.stringify(user), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
};

const setExpiredToken = (expired_at: any) => {
    setCookie('expiredAtJoyToken', encrypt(JSON.stringify(expired_at), process.env.SECRET_KEY), { maxAge: 31536000000, httpOnly: false, secure: true });
};

const deleteExpiredToken = (req?: any, res?: any) => {
    deleteCookie('expiredAtJoyToken', { req, res });
};

const getExpiredToken = () => {
    return decrypt(getCookie('expiredAtJoyToken') || '', process.env.SECRET_KEY);
};

const deleteCookieUser = (req?: any, res?: any) => {
    deleteCookie('_access', { req, res });
    deleteCookie('_refresh', { req, res });
    deleteCookie('_fb_token', { req, res });
    deleteCookie('user', { req, res });
};

const getCookieUser = (): string => {
    const fb_token = decrypt(getCookie('_fb_token') || '', process.env.SECRET_KEY);
    return fb_token;
};

const getAccessToken = (): string => {
    const _access = decrypt(getCookie('_access') || '', process.env.SECRET_KEY);
    return _access;
};

function isTokenExpired(expiresAt: number): boolean {
    return Date.now() >= expiresAt;
}

export {
    setCookieUser,
    setAuthIgnoreCookie,
    getExpiredToken,
    getCookieUser,
    setExpiredToken,
    getAccessToken,
    deleteCookieUser,
    updateCookie,
    isTokenExpired,
    clearUserCookies,
    deleteCookieAlert,
    clearAuthIgnoreCookie,
    deleteExpiredToken,
    setOnlyUser
};
