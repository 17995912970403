import React, { useState } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import { BannerNull } from '@/public/icon';
import TruncatedMarkupReact from '@/app/components/MUI/TruncatedMarkupReact';
import handleLogicText from '@/app/helpers/handleLogicContentPost';
import { useTheme } from '@mui/material';

interface PostCardWithoutMediaProps {
    content: string;
    height: string | number;
    borderRadius?: number | string;
}

const PostCardWithoutMedia: React.FC<PostCardWithoutMediaProps> = ({ content, height, borderRadius = 4 }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                borderRadius,
                padding: 5,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: `${theme.palette.grayscale.gray02}`,
                border: `1px solid ${theme.palette.grayscale.gray03}`,
            }}
            // height={height}
        >
            <TruncatedMarkupReact
                lineNumber={6}
                text={content ? handleLogicText.formatContentWithOutShort(content) : ''}
                ellipsis={
                    <Typography
                        variant="h2_12Regular"
                        sx={{ color: 'grayscale.gray06', cursor: 'pointer' }}
                    >
                        ... 더보기
                    </Typography>
                }
            />
        </Box>
    );
};

export default PostCardWithoutMedia;
