import { CheckNotificationsResponse, NotificationSettingRequest, NotificationsResponse, ReadNotificationsResponse, UserNotificationSettingsResponse } from '@/app/models/notifications';
import fetchWrapper from '../fetchWrapper';
import utilsService from '@/app/helpers/utilsService';

const BASE_URL = process.env.BASE_URL;
interface getNotificationsParams {
    cursor?: string | null;
    perPage?: number;
}
async function getNotifications({ cursor, perPage = 10 }: getNotificationsParams): Promise<NotificationsResponse> {
    try {
        const basePath = '/user/notifications';
        const params = {
            cursor: cursor,
            per_page: perPage,
        };
        const url = utilsService.constructUrlWithParams(basePath, params);
        const response = await fetchWrapper.get(`${url}`);
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function readNotifications(notificationIds?: any[]): Promise<ReadNotificationsResponse> {
    try {
        const response = await fetchWrapper.patch(`${BASE_URL}/user/notifications/read`);
        const camelizedResponse = utilsService.toCamelCase(response.data, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function checkHasNotifications(): Promise<CheckNotificationsResponse> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/user/notifications/check`);
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function enableNotifications(data: NotificationSettingRequest): Promise<UserNotificationSettingsResponse> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/notifications`, await utilsService.appendToFormData(data));
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse?.data;
    } catch (error) {
        throw error;
    }
}

async function updateFCMToken({ platform, fcmToken }: { platform: any; fcmToken: string }): Promise<UserNotificationSettingsResponse> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/fcm`, await utilsService.appendToFormData({ platform, fcm_token: fcmToken }));
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function getSettingsNotifications(): Promise<UserNotificationSettingsResponse> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/user/notifications/settings`);
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function updateNotifications({ platform, fcm }: { platform: string; fcm: string }): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/fcm`, await utilsService.appendToFormData({ platform: platform, fcm_token: fcm }));
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

export const NotificationService = {
    getNotifications,
    readNotifications,
    checkHasNotifications,
    enableNotifications,
    updateFCMToken,
    getSettingsNotifications,
    updateNotifications,
};
